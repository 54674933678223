import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import Home from './Home';
import { analytics } from '../utils';

const HomeContainer = () => {
  useEffect(() => {
    analytics.t('home: viewed home');
  }, []);

  return (
    <Box maxW="5xl" mx="auto" mt={5} p={5}>
      <Home />
    </Box>
  );
};

export default HomeContainer;
