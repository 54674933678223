import { createContext } from 'react';

const ProfileContext = createContext({
  profile: {},
  candidates: {},
  algolia: null,
  setCandidates: () => {},
  fetchProfile: () => {},
});

export default ProfileContext;
