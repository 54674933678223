import React, { useRef, useEffect, useCallback } from 'react';
import { Box, VStack, Text, Button, useToast } from '@chakra-ui/react';
import MetaMaskOnboarding from '@metamask/onboarding';
import { Auth } from 'aws-amplify';
import { showErrorToast } from './utils';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';

const SignIn = ({ fetchAuthState }) => {
  const toast = useToast();
  const onboarding = useRef(null);

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  const onMetamaskSignIn = async () => {
    try {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        if (accounts.length > 0) {
          const address = accounts[0];
          const cognitoUser = await Auth.signIn(address);
          const message = cognitoUser.challengeParam.message;
          const signature = await window.ethereum.request({
            method: 'personal_sign',
            params: [address, message],
          });
          await Auth.sendCustomChallengeAnswer(cognitoUser, signature);
          await fetchAuthState();
        }
      } else {
        onboarding.current.startOnboarding();
      }
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onPhantomSignIn = async () => {
    try {
      if ('solana' in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
          const resp = await window.solana.connect();
          const address = resp.publicKey.toString();
          const cognitoUser = await Auth.signIn(address);
          const message = cognitoUser.challengeParam.message;

          const encodedMessage = new TextEncoder('utf-8').encode(message);
          const { signature } = await window.solana.signMessage(
            encodedMessage,
            'utf8'
          );

          const decodedSig = String.fromCharCode.apply(
            null,
            new Uint16Array(signature.buffer)
          );

          await Auth.sendCustomChallengeAnswer(cognitoUser, decodedSig);
          await fetchAuthState();
        } else {
          window.open('https://phantom.app/', '_blank');
        }
      } else {
        window.open('https://phantom.app/', '_blank');
      }
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const onWCSignIn = useCallback(async () => {
    try {
      const wcProvider = new WalletConnectProvider({
        rpc: {
          1: 'https://eth-mainnet.alchemyapi.io/v2/KOQbHnSlEGupDhegcdp3J8OyfrNOgRP4',
        },
        clientMeta: {
          description: 'buildspace pro',
          url: 'https://pro.buildspace.so',
          name: 'buildspace pro',
        },
        pollingInterval: 10000,
      });
      const accounts = await wcProvider.enable();
      if (accounts.length > 0) {
        const web3 = await new Web3(wcProvider);
        const cognitoUser = await Auth.signIn(accounts[0]);
        const signature = await web3.eth.personal.sign(
          cognitoUser.challengeParam.message,
          accounts[0]
        );
        await Auth.sendCustomChallengeAnswer(cognitoUser, signature);

        await wcProvider.disconnect();
        await fetchAuthState();
      } else {
        throw Error(`Failed to auth`);
      }
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  }, [fetchAuthState, toast]);

  return (
    <Box bgGradient="linear(to-r, #fccb90, #d57eeb)" w="100%" h="100vh">
      <VStack h="100%" maxW="lg" mx="auto" justifyContent="center">
        <VStack w="100%">
          <Text
            textShadow="1px 1px #888888"
            color="white"
            fontSize="4xl"
            fontWeight="black"
            textAlign="center"
          >
            buildspace pro
          </Text>
          <Button
            h="50px"
            bg="blue.300"
            colorScheme="blue"
            shadow="md"
            w="100%"
            color="white"
            onClick={onMetamaskSignIn}
          >
            MetaMask
          </Button>
          <Button
            h="50px"
            bg="blue.300"
            colorScheme="blue"
            shadow="md"
            w="100%"
            color="white"
            onClick={onWCSignIn}
          >
            WalletConnect
          </Button>
          <Button
            h="50px"
            bg="blue.300"
            colorScheme="blue"
            shadow="md"
            w="100%"
            color="white"
            onClick={onPhantomSignIn}
          >
            Phantom
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default SignIn;
