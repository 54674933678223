import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import Candidates from './Candidates';
import { analytics } from '../utils';

const CandidatesContainer = () => {
  useEffect(() => {
    analytics.t('candidates: viewed candidates');
  }, []);

  return (
    <Box maxW="5xl" mx="auto" mt={5} p={5}>
      <Candidates />
    </Box>
  );
};

export default CandidatesContainer;
