import React, {
  useRef,
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import {
  useToast,
  Text,
  Stack,
  Box,
  Spinner,
  Button,
  Icon,
  Input,
  HStack,
  Badge,
  Flex,
} from '@chakra-ui/react';

import {
  InstantSearch,
  Stats,
  connectHits,
  connectSearchBox,
  Pagination,
  RefinementList,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  FaCheck,
  FaGithub,
  FaHeart,
  FaLinkedin,
  FaRegWindowClose,
  FaSafari,
  FaTwitter,
} from 'react-icons/fa';
import { convertTZ, showErrorToast } from '../utils';
import actions from '../actions';
import ProfileContext from '../Context/ProfileContext';

const Candidates = () => {
  const profileContext = useContext(ProfileContext);
  const [algoliaConfigured, setAlgoliaConfigured] = useState(false);
  const searchClientRef = useRef(null);
  const toast = useToast();

  const [loading, setLoading] = useState({});
  const [localIgnores, setLocalIgnores] = useState({});

  const SearchBox = ({ currentRefinement, refine }) => (
    <HStack w="100%">
      <Input
        placeholder="Search here..."
        h="50px"
        type="search"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
      />
      <HStack
        justifyContent="center"
        rounded="lg"
        bg="gray.100"
        h="50px"
        minW="15%"
        px={4}
      >
        <Text fontSize="md">
          <Stats
            translations={{
              stats(nbHits) {
                return `${nbHits.toLocaleString()} candidates`;
              },
            }}
          />
        </Text>
      </HStack>
    </HStack>
  );

  useEffect(() => {
    if (profileContext && profileContext.algolia && !searchClientRef.current) {
      searchClientRef.current = algoliasearch(
        profileContext.algolia.app_id,
        profileContext.algolia.key
      );
      setAlgoliaConfigured(true);
    }
  }, [profileContext]);

  const createIntro = useCallback(
    async candidate_id => {
      setLoading(prevLoading => {
        prevLoading[candidate_id] = true;
        return { ...prevLoading };
      });

      try {
        const { email, candidate_id: c_id } = await actions.createIntro(
          candidate_id
        );
        profileContext.setIntros(prevIntros => {
          prevIntros[c_id] = email;
          return { ...prevIntros };
        });
      } catch (err) {
        showErrorToast(toast, err.message);
      }

      setLoading(prevLoading => {
        prevLoading[candidate_id] = false;
        return { ...prevLoading };
      });
    },
    [profileContext, toast]
  );

  const ignoreCandidate = useCallback(
    async candidate_id => {
      console.log('Candidate: ', candidate_id);
      setLoading(prevLoading => {
        prevLoading[candidate_id] = true;
        return { ...prevLoading };
      });

      try {
        await actions.updateCandidate(candidate_id, 'ignore');
        setLocalIgnores(prevLocalIgnores => {
          prevLocalIgnores[candidate_id] = true;
          return { ...prevLocalIgnores };
        });
      } catch (err) {
        showErrorToast(toast, err.message);
      }

      setLoading(prevLoading => {
        prevLoading[candidate_id] = false;
        return { ...prevLoading };
      });
    },
    [toast]
  );

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits }) => {
    hits = hits.sort((a, b) => {
      const candidateA = profileContext.candidates[a.objectID];
      const candidateB = profileContext.candidates[b.objectID];
      if (candidateA && candidateB) {
        return (
          new Date(candidateB.date).getTime() -
          new Date(candidateA.date).getTime()
        );
      }
      return 0;
    });
    return (
      <Stack w="100%">
        {hits.filter(h => !localIgnores[h.objectID]).map(Hit)}
      </Stack>
    );
  };

  const CustomHits = connectHits(Hits);

  const Hit = hit => {
    const isLiked = () => {
      return (
        hit.visible_by &&
        !!hit.visible_by.find(
          id => id === `group/${profileContext.profile.company_id}`
        )
      );
    };

    const renderRoles = () => (
      <Flex wrap="wrap">
        {hit.roles.map(r => (
          <Badge
            bg="purple.100"
            color="gray.800"
            textTransform="capitalize"
            rounded="md"
            fontWeight="medium"
            mr={1}
            my={1}
            key={r}
            px={2}
            py={1}
          >
            {r}
          </Badge>
        ))}
      </Flex>
    );

    return (
      <Box
        position="relative"
        textAlign="left"
        w="100%"
        key={hit.objectID}
        p={4}
        borderWidth="1px"
        borderColor="gray.200"
        rounded="md"
      >
        <Stack w="100%" spacing={4}>
          <Stack w="100%" spacing={1}>
            <HStack justifyContent="space-between">
              <HStack>
                {isLiked() && <Icon color="red.400" as={FaHeart} />}
                <Text fontSize="sm" fontWeight="bold">
                  {hit.name}
                </Text>
                <Text color="gray.400">|</Text>
                <Text fontSize="xs" color="gray.500">
                  {hit.location}
                </Text>
              </HStack>
              <HStack mt={2} spacing={2}>
                {hit.linkedin && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => window.open(hit.linkedin)}
                  >
                    <Icon color="white" as={FaLinkedin} />
                  </Button>
                )}
                {hit.github && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => window.open(hit.github)}
                  >
                    <Icon color="white" as={FaGithub} />
                  </Button>
                )}
                {hit.website && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => window.open(hit.website)}
                  >
                    <Icon color="white" as={FaSafari} />
                  </Button>
                )}
                {hit.twitter && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => window.open(hit.twitter)}
                  >
                    <Icon color="white" as={FaTwitter} />
                  </Button>
                )}
              </HStack>
            </HStack>

            <Stack w="100%" spacing={2}>
              <Text fontSize="xs" fontWeight="medium">
                {hit.bio}
              </Text>

              {renderRoles()}
            </Stack>
          </Stack>
          <Box borderWidth="1px" width="100%" borderColor="gray.50" my={5} />
          <Stack wrap="wrap" justifyContent="right" spacing={2}>
            {profileContext.candidates[hit.objectID] ? (
              <Stack>
                <Text fontSize="md">
                  {profileContext.candidates[hit.objectID].email}
                </Text>
                <Text fontSize="xs" color="gray.600">
                  Requested on{' '}
                  {convertTZ(profileContext.candidates[hit.objectID].date, {
                    mode: 'date',
                  })}
                </Text>
              </Stack>
            ) : (
              <HStack justifyContent="right">
                <Button
                  bg="red.100"
                  color="red.500"
                  fontWeight="medium"
                  fontSize="sm"
                  isLoading={loading[hit.objectID]}
                  onClick={() => ignoreCandidate(hit.objectID)}
                >
                  <Icon mr={2} as={FaRegWindowClose} />
                  Not interested
                </Button>
                <Button
                  bg="blue.400"
                  color="white"
                  fontWeight="medium"
                  fontSize="sm"
                  isLoading={loading[hit.objectID]}
                  onClick={() => createIntro(hit.objectID)}
                >
                  <Icon mr={2} as={FaCheck} />
                  Request Email
                </Button>
              </HStack>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  };

  if (!algoliaConfigured) return <Spinner />;

  return (
    <Box maxW="5xl" mx="auto" mt={5} p={5}>
      <div className="ais-InstantSearch">
        <InstantSearch
          indexName={profileContext.algolia.index}
          searchClient={searchClientRef.current}
        >
          <Box d="none">
            <RefinementList
              defaultRefinement={profileContext.profile.company_id}
              attribute={'liked_by'}
            />
          </Box>
          <Stack alignItems="center">
            <CustomSearchBox />
            <CustomHits />
            <Pagination />
          </Stack>
        </InstantSearch>
      </div>
    </Box>
  );
};

export default Candidates;
