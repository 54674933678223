import React, {
  useRef,
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import {
  useToast,
  Text,
  Stack,
  Box,
  Spinner,
  Button,
  Icon,
  Input,
  HStack,
  Badge,
  Flex,
  Link as ChakraLink,
} from '@chakra-ui/react';

import {
  InstantSearch,
  connectHits,
  connectSearchBox,
  Pagination,
  RefinementList,
  RangeInput,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  FaExternalLinkAlt,
  FaGithub,
  FaHeart,
  FaLinkedin,
  FaRegWindowClose,
  FaSafari,
  FaTwitter,
} from 'react-icons/fa';
import { analytics, shortAddress, showErrorToast } from '../utils';
import actions from '../actions';
import ProfileContext from '../Context/ProfileContext';

const Home = () => {
  const profileContext = useContext(ProfileContext);
  const [algoliaConfigured, setAlgoliaConfigured] = useState(false);
  const searchClientRef = useRef(null);
  const inputRef = useRef(null);

  const toast = useToast();

  const [loading, setLoading] = useState({});
  const [localIgnores, setLocalIgnores] = useState({});

  const params = new URLSearchParams(window.location.search);
  const initialQuery = params.get('q');

  const SearchBox = ({ currentRefinement, refine }) => {
    if (initialQuery && !inputRef.current) {
      refine(initialQuery);
    }

    return (
      <Input
        ref={inputRef}
        placeholder="Search here..."
        h="50px"
        type="search"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
      />
    );
  };

  useEffect(() => {
    if (profileContext && profileContext.algolia && !searchClientRef.current) {
      searchClientRef.current = algoliasearch(
        profileContext.algolia.app_id,
        profileContext.algolia.key
      );
      setAlgoliaConfigured(true);
    }
  }, [profileContext]);

  const createIntro = useCallback(
    async candidate_id => {
      setLoading(prevLoading => {
        prevLoading[candidate_id] = true;
        return { ...prevLoading };
      });

      analytics.t('home: create intro', { candidate_id });

      try {
        const { email, candidate_id: c_id } = await actions.createIntro(
          candidate_id
        );

        profileContext.setCandidates(prevIntros => {
          prevIntros[c_id] = { email, date: new Date().toISOString() };
          return { ...prevIntros };
        });
      } catch (err) {
        showErrorToast(toast, err.message);
      }

      setLoading(prevLoading => {
        prevLoading[candidate_id] = false;
        return { ...prevLoading };
      });
    },
    [profileContext, toast]
  );

  const ignoreCandidate = useCallback(
    async candidate_id => {
      setLoading(prevLoading => {
        prevLoading[candidate_id] = true;
        return { ...prevLoading };
      });

      analytics.t('home: ignore candidate', { candidate_id });

      try {
        await actions.updateCandidate(candidate_id, 'ignore');
        setLocalIgnores(prevLocalIgnores => {
          prevLocalIgnores[candidate_id] = true;
          return { ...prevLocalIgnores };
        });
      } catch (err) {
        showErrorToast(toast, err.message);
      }

      setLoading(prevLoading => {
        prevLoading[candidate_id] = false;
        return { ...prevLoading };
      });
    },
    [toast]
  );

  const CustomSearchBox = connectSearchBox(SearchBox);

  const Hits = ({ hits }) => (
    <Stack w="100%">
      {hits.filter(h => !localIgnores[h.objectID]).map(Hit)}
    </Stack>
  );

  const CustomHits = connectHits(Hits);

  const Hit = hit => {
    const isLiked = () => {
      return (
        hit.visible_by &&
        !!hit.visible_by.find(
          id => id === `group/${profileContext.profile.company_id}`
        )
      );
    };

    const renderRoles = () => (
      <Stack spacing={0}>
        <Flex wrap="wrap">
          {hit.roles.slice(0, 3).map(r => (
            <Badge
              bg="purple.100"
              color="gray.800"
              textTransform="capitalize"
              rounded="md"
              fontWeight="medium"
              mr={1}
              my={1}
              key={r}
              px={2}
              py={1}
            >
              {r}
            </Badge>
          ))}
        </Flex>
        {hit.eth_address && (
          <Flex>
            <ChakraLink href={hit.eth_address.link} isExternal>
              <HStack
                bg="blue.100"
                rounded="md"
                px={2}
                fontSize="xs"
                fontWeight="medium"
                py={1}
              >
                <Text>{shortAddress(hit.eth_address.title)}</Text>
                <Icon color="gray.700" as={FaExternalLinkAlt} />
              </HStack>
            </ChakraLink>
          </Flex>
        )}
      </Stack>
    );

    return (
      <Box
        position="relative"
        textAlign="left"
        w="100%"
        key={hit.objectID}
        p={4}
        borderWidth="1px"
        borderColor="gray.200"
        rounded="md"
      >
        <Stack w="100%" spacing={4}>
          <Stack w="100%" spacing={1}>
            <HStack justifyContent="space-between">
              <HStack>
                {isLiked() && <Icon color="red.400" as={FaHeart} />}
                <Text fontSize="md" fontWeight="bold">
                  {hit.name}
                </Text>
              </HStack>
              <HStack mt={2} spacing={2}>
                {hit.linkedin && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => {
                      analytics.t('home: clicked candidate social', {
                        id: hit.objectID,
                        type: 'linkedin',
                      });
                      window.open(hit.linkedin);
                    }}
                  >
                    <Icon color="white" as={FaLinkedin} />
                  </Button>
                )}
                {hit.github && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => {
                      analytics.t('home: clicked candidate social', {
                        id: hit.objectID,
                        type: 'github',
                      });
                      window.open(hit.github);
                    }}
                  >
                    <Icon color="white" as={FaGithub} />
                  </Button>
                )}
                {hit.website && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => {
                      analytics.t('home: clicked candidate social', {
                        id: hit.objectID,
                        type: 'website',
                      });
                      window.open(hit.website);
                    }}
                  >
                    <Icon color="white" as={FaSafari} />
                  </Button>
                )}
                {hit.twitter && (
                  <Button
                    bg="purple.400"
                    colorScheme="purple"
                    size="xs"
                    onClick={() => {
                      analytics.t('home: clicked candidate social', {
                        id: hit.objectID,
                        type: 'twitter',
                      });
                      window.open(hit.twitter);
                    }}
                  >
                    <Icon color="white" as={FaTwitter} />
                  </Button>
                )}
              </HStack>
            </HStack>

            <Stack w="100%" spacing={4}>
              <Box>
                <Text fontSize="sm" color="gray.600">
                  {hit.job_experience === '0'
                    ? `Student · `
                    : hit.job_experience
                    ? `${hit.job_experience} years of exp · `
                    : ''}
                  {hit.location}
                </Text>
                <Text mt={1} fontSize="xs" fontWeight="medium">
                  {hit.bio}
                </Text>
              </Box>
              {hit.exp_companies && hit.exp_companies.length > 0 && (
                <Box>
                  <Text fontSize="xs" color="gray.500">
                    Experience
                  </Text>
                  <Stack my={1} spacing={4}>
                    {hit.exp_companies.map(c => (
                      <Box key={c.name}>
                        <Text fontSize="sm" fontWeight="medium">
                          {c.name}
                        </Text>
                        <Text fontSize="xs" color="gray.600">
                          {c.title}
                        </Text>
                        <HStack fontSize="xs" color="gray.600">
                          <Text>{c.start_date}</Text>
                          <Text>-</Text>
                          <Text>{c.current ? 'Current' : c.end_date}</Text>
                        </HStack>
                        <Text fontSize="xs" color="gray.600">
                          {c.description}
                        </Text>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              )}
              {renderRoles()}
            </Stack>
          </Stack>
          <Box borderWidth="1px" width="100%" borderColor="gray.50" my={5} />
          <Stack wrap="wrap" spacing={2}>
            {profileContext.candidates[hit.objectID] ? (
              <Text fontSize="md">
                {profileContext.candidates[hit.objectID].email}
              </Text>
            ) : (
              <HStack>
                <Button
                  bg="red.100"
                  color="red.500"
                  fontWeight="medium"
                  fontSize="sm"
                  isLoading={loading[hit.objectID]}
                  onClick={() => ignoreCandidate(hit.objectID)}
                >
                  <Icon mr={2} as={FaRegWindowClose} />
                  Not interested
                </Button>
                <Button
                  bg="blue.400"
                  color="white"
                  fontWeight="bold"
                  fontSize="sm"
                  isLoading={loading[hit.objectID]}
                  onClick={() => createIntro(hit.objectID)}
                >
                  <Text mr={1}>☀️</Text>
                  say gm
                </Button>
              </HStack>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  };

  if (!algoliaConfigured) return <Spinner />;

  return (
    <Box maxW="5xl" mx="auto" mt={5} p={5}>
      <div className="ais-InstantSearch">
        <InstantSearch
          indexName={profileContext.algolia.index}
          searchClient={searchClientRef.current}
        >
          <HStack spacing={4} alignItems="top">
            <Stack
              borderColor="gray.200"
              borderWidth="1px"
              p={3}
              bg="gray.50"
              rounded="lg"
              textAlign="left"
              spacing={4}
              fontSize="sm"
              w={'17rem'}
            >
              <Box>
                <Text fontWeight="bold">Roles</Text>
                <RefinementList attribute={'roles'} />
              </Box>
              <Box>
                <Text fontWeight="bold">Remote Preferred</Text>
                <RefinementList attribute={'remote_pref'} />
              </Box>
              <Box>
                <Text fontWeight="bold">Timeline</Text>
                <RefinementList attribute={'timeline'} />
              </Box>
              <Box>
                <Text fontWeight="bold">Sectors</Text>
                <RefinementList attribute={'sectors'} />
              </Box>
              <Box>
                <Text fontWeight="bold">Experience (Years)</Text>
                <RangeInput attribute="job_experience_int" />
              </Box>
              <Box>
                <Text mb={1} fontWeight="bold">
                  Country
                </Text>
                <RefinementList searchable={true} attribute={'country'} />
              </Box>
            </Stack>
            <Stack w="100%" alignItems="center">
              <CustomSearchBox />
              <CustomHits />
              <Pagination />
            </Stack>
          </HStack>
        </InstantSearch>
      </div>
    </Box>
  );
};

export default Home;
